<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog v-model="payDialog" width="600px">
      <v-card style="border-radius: 20px" class="pa-3">
        <v-card-title>
          <h4 class="primary--text">پرداخت درخواست</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6>
            <v-row>
              <v-col>نام مراجعه کننده: {{ selected.name }}</v-col>
            </v-row>
            <v-row>
              <v-col
                >مبلغ کل:
                {{ Number(selected.cost).toLocaleString() }} ریال</v-col
              >
              <v-col
                >مبلغ پرداخت شده:
                {{ Number(selected.paidCost).toLocaleString() }} ریال</v-col
              >
            </v-row>
            <v-row>
              <v-col
                >مبلغ باقی‌مانده:
                {{ Number(selected.remained).toLocaleString() }} ریال</v-col
              >
            </v-row>
          </h6>

          <hr />
          <vuetify-money
            v-model="payAmount"
            label="مبلغ (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            :rules="[validCost]"
            outlined
            dense
            class="mx-auto details-hidden"
          />
          <div class="red--text text-right h5" v-if="payAmount && !validCost()">
            *مبلغ وارد شده از مبلغ مانده بیشتر است
          </div>
          <v-row class="time-row mt-1">
            <v-col
              ><v-select
                v-model="transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه پرداخت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              transferType == 'card' ||
              transferType == 'cash-pos' ||
              transferType == 'cheque'
            "
          >
            <v-col>
              <div v-if="transferType == 'card'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات کارت‌ها</h5>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mb-4"
                    v-model="cardNumberSender"
                    label="شماره کارت فرستنده*"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="cardNumberReciever"
                    label="شماره کارت گیرنده*"
                  >
                  </v-text-field>
                </div>
              </div>
              <div v-else-if="transferType == 'cash-pos'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h6 class="py-3 red--text" v-if="!cashPosValid('payment')">
                    ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.
                  </h6>
                  <vuetify-money
                    v-model="posAmount"
                    label="*مبلغ کارتخوان(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    @input="onPosInput('payment')"
                    outlined
                    dense
                    class="mx-auto mb-2 details-hidden"
                  />
                  <vuetify-money
                    v-model="cashAmount"
                    label="*مبلغ نقدی(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mx-auto details-hidden"
                  />
                </div>
              </div>
              <div v-else-if="transferType == 'cheque'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات چک</h5>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeBankName"
                    label="نام بانک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeNumber"
                    label="شماره چک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeSayyad"
                    label="شناسه صیاد"
                    class="mb-4"
                  >
                  </v-text-field>
                  <span id="newChequeDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="chequeDueDate"
                      label=" تاریخ چک*"
                      :editable="true"
                      class="date-input mb-4"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="chequeDueDate"
                    element="newChequeDate"
                    color="#00a7b7"
                  />
                  <v-textarea
                    outlined
                    dense
                    type="text"
                    v-model="chequeDescription"
                    label="توضیحات چک"
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isPaying"
            @click="payDialog = false"
          >
            لغو
          </v-btn>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isPaying"
            @click="submitPayment()"
            :disabled="!checkCost() || !cashPosValid('payment')"
          >
            ثبت پرداخت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--<v-dialog v-model="refundDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <h4 class="primary--text">بازپرداخت</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6>
            <v-row>
              <span
                >شما در حال بازپرداخت درخواست
                 {{selected.demandType}}
                 بیمار
                 {{selected.patientName}}
               هستید.</span
              >
            </v-row>
            <v-row>
              <v-col
                >مبلغ:
                {{ Number(selected.totalCost).toLocaleString() }} ریال</v-col
              >
            </v-row>
            <v-row class="time-row mt-1">
              <v-col
                ><v-select
                  v-model="transferType"
                  :items="paymentTypes"
                  item-text="text"
                  item-value="value"
                  label="نحوه پرداخت"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              class="time-row"
              v-if="transferType == 'card' || transferType == 'cheque' || transferType == 'cash-pos'"
            >
              <v-col>
                <div v-if="transferType == 'card'">
                  <div
                    style="border: 1px grey solid; border-radius: 8px"
                    class="px-3 py-2"
                  >
                    <h5>اطلاعات کارت‌ها</h5>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      class="mb-4"
                      v-model="cardNumberSender"
                      label="شماره کارت فرستنده*"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      v-model="cardNumberReciever"
                      label="شماره کارت گیرنده*"
                    >
                    </v-text-field>
                  </div>
                </div>
   <div v-if="transferType == 'cash-pos'">
                <div style="border: 1px grey solid; border-radius: 8px" class="px-3 py-2">
                  <h6 class="mb-2 red--text" v-if="!cashPosValid('refund')">! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.</h6>
                  <vuetify-money
                    v-model="posAmount"
                    label="*مبلغ کارتخوان(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    @input="onPosInput('refund')"
                    outlined
                    dense
                    class="mx-auto mb-2 details-hidden"
                  />
                  <vuetify-money
                    v-model="cashAmount"
                    label="*مبلغ نقدی(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mx-auto details-hidden"
                  />    
                </div>
              </div>
                <div v-if="transferType == 'cheque'">
                  <div
                    style="border: 1px grey solid; border-radius: 8px"
                    class="px-3 py-2"
                  >
                    <h5>اطلاعات چک</h5>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeBankName"
                      label="نام بانک*"
                      class="mb-4"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeNumber"
                      label="شماره چک*"
                      class="mb-4"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeSayyad"
                      label="شناسه صیاد"
                      class="mb-4"
                    >
                    </v-text-field>
                    <span id="newChequeDate">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="chequeDueDate"
                        label=" تاریخ چک*"
                        :editable="true"
                        class="date-input mb-4"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="chequeDueDate"
                      element="newChequeDate"
                      color="#00a7b7"
                    />
                    <v-textarea
                      outlined
                      dense
                      type="text"
                      v-model="chequeDescription"
                      label="توضیحات چک"
                    >
                    </v-textarea>
                  </div>
                </div>
              </v-col>
            </v-row>
          </h6>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isRefunding"
            @click="refundDialog = false"
          >
            لغو
          </v-btn>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isRefunding"
            :disabled="!refundDisabled() || !cashPosValid('refund')"
            @click="submitRefund() "
          >
            ثبت بازپرداخت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <v-dialog v-model="infoDialog" width="600">
      <v-card class="px-3 py-5" style="border-radius: 20px">
        <v-container>
          <v-row class="px-2">
            <v-col class="text-right">
              <h4 class="cyan--text text--darken-4">راهنما</h4>
            </v-col>
            <v-col class="text-left">
              <span @click="infoDialog = false" style="cursor: pointer">
                <v-icon color="red">mdi-close</v-icon>
              </span>
            </v-col>
          </v-row>
          <v-card-text class="text-justify blue-grey--text darken-4">
            <h5>همکار گرامی،</h5>
            <p class="ps-5 mt-2 h3" style="line-height: 30px">
              لیست زیر شامل مراجعات خدمات دندانپزشکی پرداخت نشده‌ی بیماران
              میباشد، در صورتی که پرداختی بصورت کامل صورت گیرد، سطر مربوطه به
              صورت خودکار از لیست حذف میشود.
            </p>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <!--<h3>بخش مالی خدمات در منزل</h3>-->

        <h3 v-if="deviceType == 'mobile'">حساب‌های پرداختنی دندانپزشکی</h3>

        <h3 v-else>حساب‌های پرداختنی خدمات دندانپزشکی</h3>
        <div style="cursor: pointer" @click="infoDialog = true">
          <v-icon class="ps-2 mb-2" large color="primary"
            >mdi-information-outline</v-icon
          >
        </div>
      </v-card-title>
      <!--<v-tabs fixed-tabs background-color="transparent" v-model="tab">
        <v-tab @click="getServicesInfoSeperately" class="tab-title">
          پرداخت‌ها
        </v-tab>-->
      <!--<v-tab @click="getRefundLists" class="tab-title"> بازپرداخت‌ها </v-tab>-->
      <!--</v-tabs>-->
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />
      <v-card class="cards tab-item" v-else>
        <!--<v-tabs-items v-model="tab">-->
        <!-- پرداخت‌ها -->
        <!--<v-tab-item>-->
        <v-card class="cards pa-3">
          <div style="float: left" class="me-3 ms-3">
            <v-btn
              text
              class="refresh-btn pe-0 ps-0"
              @click="getServicesInfoSeperately()"
              :loading="Busy"
              ><v-icon> refresh</v-icon></v-btn
            >
          </div>
          <v-card-text class="mt-4">
            <div>
              <v-text-field
                v-model="Filter"
                label="جستجو"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="text-right mb-2 mt-2 me-0 ms-0 search-input dr-search"
                filled
                rounded
                clearable
              ></v-text-field>
              <b-table
                responsive
                show-empty
                head-variant="light"
                :fields="Fields"
                :items="Items"
                empty-text="رکوردی برای نمایش وجود ندارد"
                empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                :filter="Filter"
                :busy="Busy"
                :current-page="CurrentPage"
                :per-page="PerPage"
                @filtered="onFiltered"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{
                      typeof data.value === "number"
                        ? Number(data.value).toLocaleString()
                        : data.value
                    }}
                  </div>
                </template>
                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      class="primary-btn pa-2"
                      @click="openPayModal(data.item)"
                      style="width: 100%; margin-top: 2px"
                      ><v-icon>mdi-cash-multiple</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-primary my-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
              <v-pagination
                v-model="CurrentPage"
                :length="Math.ceil(TotalRows / PerPage)"
                :total-visible="5"
                prev-icon="arrow_back"
                next-icon="arrow_forward"
              ></v-pagination>
              <v-select
                label="تعداد در هر صفحه:"
                style="width: 150px"
                v-model="PerPage"
                :items="perPageOptions"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
          </v-card-text>
        </v-card>
        <!--</v-tab-item>-->
        <!--بازپرداخت‌ها-->
        <!--<v-tab-item>
            <v-card class="cards pa-3">
              <div style="float: left" class="me-3 ms-3">
                <v-btn
                  text
                  class="refresh-btn pe-0 ps-0"
                  @click="getRefundLists()"
                  :loading="reBusy"
                  ><v-icon> refresh</v-icon></v-btn
                >
              </div>
              <v-card-text class="mt-4">
                <div>
                  <v-text-field
                    v-model="reFilter"
                    label="جستجو"
                    single-line
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    class="text-right mb-2 mt-2 me-0 ms-0 search-input dr-search"
                    filled
                    rounded
                    clearable
                  ></v-text-field>
                  <b-table
                    responsive
                    show-empty
                    head-variant="light"
                    :fields="reFields"
                    :items="refunds"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    :filter="reFilter"
                    :busy="reBusy"
                    :current-page="reCurrentPage"
                    :per-page="rePerPage"
                    @filtered="onFiltered"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="primary-btn pa-2"
                          @click="openRefundDialog(data.item)"
                          style="width: 100%; margin-top: 2px"
                          ><v-icon>mdi-cash-multiple</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center text-primary my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <v-pagination
                    v-model="reCurrentPage"
                    :length="Math.ceil(reTotalRows / rePerPage)"
                    :total-visible="5"
                    prev-icon="arrow_back"
                    next-icon="arrow_forward"
                  ></v-pagination>
                  <v-select
                    label="تعداد در هر صفحه:"
                    style="width: 150px"
                    v-model="rePerPage"
                    :items="perPageOptions"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>-->
        <!--</v-tabs-items>-->
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div class="container mb-0" style="border: 2px solid black">
        <div
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
            padding: 20px;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>
        <div
          style="direction: rtl; font-weight: 900; padding-right: 10px"
          class="ma-3"
        >
          <br />
          {{ "نام مراجعه کننده: " + selected.name }}
          <br />
          {{ "مبلغ : " + Number(selected.cost).toLocaleString() + "ریال" }}
          <br />
          {{
            " مبلغ پرداخت شده: " +
            (Number(selected.paidCost) + Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{
            "مبلغ باقی‌مانده: " +
            (Number(selected.remained) - Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{ " تاریخ: " + currentDate }}
          <br />
          {{
            "نوع پرداخت: " +
            paymentTypes.filter((x) => x.value == transferType)[0].text
          }}
          <br />
          <div
            v-if="
              transferType == 'card' ||
              transferType == 'cash-pos' ||
              transferType == 'cheque'
            "
          >
            <div v-if="transferType == 'card'">
              شماره کارت فرستنده:
              {{ cardNumberSender ? cardNumberSender : "-" }}
              <br />
              شماره کارت فرستنده:
              {{ cardNumberReciever ? cardNumberReciever : "-" }}
            </div>
            <div v-else-if="transferType == 'cash-pos'">
              مبلغ کارتخوان:
              {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
              <br />
              مبلغ نقدی:
              {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }} ریال
            </div>
            <div v-else-if="transferType == 'cheque'">
              نام بانک:
              {{ chequeBankName ? chequeBankName : "-" }}
              <br />
              شماره چک:
              {{ chequeNumber ? chequeNumber : "-" }}
              <br />
              شناسه صیاد:
              {{ chequeSayyad ? chequeSayyad : "-" }}
              <br />
              تاریخ چک:
              {{ chequeDueDate ? chequeDueDate : "-" }}
              <br />
              توضیحات چک:
              <p
                style="
                  text-align: justify;
                  line-height: 28px;
                  padding: 0 5px;
                  margin-top: 0px;
                "
              >
                {{ chequeDescription ? chequeDescription : "-" }}
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- receipt -->
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VueBarcode from "vue-barcode";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";

const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    barcode: VueBarcode,
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      payDialog: false,
      payAmount: "",
      selected: {},
      reCurrentPage: 1,
      CurrentPage: 1,
      rePerPage: 10,
      PerPage: 10,
      tab: null,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      transferType: "cash",
      cardNumberSender: "",
      cardNumberReciever: "",
      TotalRows: "",
      reTotalRows: "",
      Filter: "",
      reFilter: "",
      Busy: false,
      reBusy: false,
      isPaying: false,
      isRefunding: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام بیمار" },
        { key: "date", label: "تاریخ و ساعت" },
        { key: "cost", label: "مبلغ (ریال)" },
        { key: "paidCost", label: "مبلغ پرداخت شده (ریال)" },
        { key: "operation", label: "پرداخت" },
      ],
      //reFields: [
      //  { key: "index", label: "#" },
      //  { key: "patientName", label: "نام بیمار" },
      //  { key: "demandType", label: "نوع درخواست" },
      //  { key: "payment", label: "مبلغ (ریال)" },
      //  { key: "operation", label: "بازپرداخت" },
      //],
      //excelFields: [
      //  { field: "name", label: "نام بیمار" },
      //  { field: "labTermNumber", label: "شماره پذیرش آزمایش" },
      //  { field: "date", label: "تاریخ و ساعت" },
      //  { field: "cost", label: "مبلغ (ریال)" },
      //  { field: "paidCost", label: "مبلغ پرداخت شده (ریال)" },
      //],
      Items: [],
      refunds: [],
      role: "",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      reciept: {},
      isMounted: false,
      refundDialog: false,
      cashAmount: 0,
      posAmount: 0,
      infoDialog: false,
      chequeBankName: "",
      chequeNumber: "",
      chequeSayyad: "",
      chequeDueDate: "",
      chequeDescription: "",
    };
  },

  methods: {
    getServices() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentistry/payment/unpaidLists",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.isMounted = true;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    getServicesInfoSeperately() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentistry/payment/unpaidLists",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    openPayModal(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.selected.remained = this.selected.cost - this.selected.paidCost;
      this.payAmount = this.selected.remained;
      this.transferType = "cash";
      this.cardNumberSender = "";
      this.cardNumberReciever = "";
      this.chequeBankName = "";
      this.chequeNumber = "";
      this.chequeSayyad = "";
      this.chequeDueDate = "";
      this.chequeDescription = "";
      this.cashAmount = 0;
      this.posAmount = 0;
      this.payDialog = true;
    },
    //openRefundDialog(item) {
    //  this.selected = JSON.parse(JSON.stringify(item));
    //  this.transferType = "cash";
    //  this.cardNumberSender = "";
    //  this.cardNumberReciever = "";
    //  this.chequeBankName = "";
    //  this.chequeAmount = "";
    //  this.chequeNumber = "";
    //  this.chequeSayyad = "";
    //  this.chequeDueDate = "";
    //  this.chequeDueDate = "";
    //  this.chequeDescription = "";
    //   this.cashAmount=0;
    //  this.posAmount=0;
    //  this.refundDialog = true;
    //},
    submitPayment() {
      this.isPaying = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentistry/payment/pay",
          {
            visitId: this.selected.id,
            paidCost: this.payAmount,
            transferType: this.transferType,
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
            chequeAmount: this.payAmount,
            chequeNumber:
              this.transferType == "cheque" ? this.chequeNumber : "",
            chequeBankName:
              this.transferType == "cheque" ? this.chequeBankName : "",
            chequeDueDate:
              this.transferType == "cheque" ? this.chequeDueDate : "",
            chequeSayyad:
              this.transferType == "cheque" ? this.chequeSayyad : "",
            chequeDescription:
              this.transferType == "cheque" ? this.chequeDescription : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isPaying = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.payDialog = false;
            this.getServices();
            this.printReceipt();
            this.isMounted = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.isPaying = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    //submitRefund() {
    //  this.isRefunding = true;
    //  this.$http
    //    .post(
    //      this.baseUrl + "/clinic/homeCare/financial/refund",
    //      {
    //        outRequestId: this.selected.id,
    //        transferType: this.transferType,
    //        chequeAmount:
    //          this.transferType == "cheque" ? this.selected.payment : "",
    //        chequeNumber:
    //          this.transferType == "cheque" ? this.chequeNumber : "",
    //        chequeBankName:
    //          this.transferType == "cheque" ? this.chequeBankName : "",
    //        chequeDueDate:
    //          this.transferType == "cheque" ? this.chequeDueDate : "",
    //        chequeSayyad:
    //          this.transferType == "cheque" ? this.chequeSayyad : "",
    //        chequeDescription:
    //          this.transferType == "cheque" ? this.chequeDescription : "",
    //        cardNumberSender:
    //          this.transferType == "card" ? this.cardNumberSender : "",
    //        cardNumberReciever:
    //          this.transferType == "card" ? this.cardNumberReciever : "",
    //      },
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      this.isRefunding = false;
    //      if (res.status == 200) {
    //        this.toast(res.data, "success");
    //        this.refundDialog = false;
    //        this.getRefundLists();
    //        this.isMounted = false;
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //      }
    //    })
    //    .catch((err) => {
    //      this.isRefunding = false;
    //      this.toast("خطا: " + err.response.data, "error");
    //    });
    //},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    validCost(cost) {
      // NOTE (m-isID) to check requested amount is not bigger than remained cost
      return this.payAmount
        ? this.payAmount <= this.selected.remained
          ? true
          : false
        : true;
    },
    checkCost() {
      // NOTE (m-isID) to check the validity of the national code
      if (this.payAmount) {
        if (this.payAmount <= this.selected.remained) {
          if (this.transferType == "card") {
            if (this.cardNumberSender && this.cardNumberReciever) {
              return true;
            } else return false;
          } else if (this.transferType == "cash-pos") {
            if (this.cashAmount && this.posAmount) {
              return true;
            } else return false;
          } else if (this.transferType == "cheque") {
            if (
              this.chequeBankName &&
              this.chequeNumber &&
              this.chequeDueDate
            ) {
              return true;
            } else return false;
          } else return true;
        } else return false;
      } else return false;
    },
    //refundDisabled() {
    //  // NOTE (m-isID) to check the validity of the national code
    //  if (this.transferType == "card") {
    //    if (this.cardNumberSender && this.cardNumberReciever) {
    //      return true;
    //    } else return false;
    //  } else if (this.transferType == "cheque") {
    //    if (this.chequeBankName && this.chequeNumber && this.chequeDueDate) {
    //      return true;
    //    } else return false;
    //  } else return true;
    //},
    //getRefundLists() {
    //  this.$http
    //    .post(
    //      this.baseUrl + "/clinic/homeCare/financial/needRefundLists",
    //      {},
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      this.isPaying = false;
    //      if (res.status == 200) {
    //        this.refunds = res.data;
    //        this.isMounted = true;
    //        this.reTotalRows = this.Items.length;
    //        this.reCurrentPage = 1;
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //      }
    //    })
    //    .catch((err) => {
    //      this.isPaying = false;
    //      this.toast("خطا: " + err.response.data, "error");
    //    });
    //},
    onPosInput(type) {
      if (type == "payment") {
        if (parseInt(this.posAmount) < parseInt(this.payAmount)) {
          this.cashAmount = parseInt(this.payAmount) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      } else {
        if (parseInt(this.posAmount) < parseInt(this.selected.payment)) {
          this.cashAmount =
            parseInt(this.selected.payment) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      }
    },
    cashPosValid(type) {
      if (this.transferType == "cash-pos") {
        if (this.cashAmount && this.posAmount) {
          if (type == "payment") {
            if (
              parseInt(this.payAmount) ==
              parseInt(this.cashAmount) + parseInt(this.posAmount)
            ) {
              return true;
            } else return false;
          } else if (type == "refund") {
            return parseInt(this.cashAmount) + parseInt(this.posAmount) ==
              parseInt(this.selected.payment)
              ? true
              : false;
          }
        } else return false;
      } else {
        return true;
      }
    },
    printReceipt() {
      // printing receipt information for user
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
  },
  computed: {
    ...mapGetters(["paymentTypes"]),
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getServices();
  },
};
</script>
<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80% !important;
}
</style>
